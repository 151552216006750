#home-page,
#subscription-plan-page {
  min-height: 100vh;
  background-color: #f2f7fb;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

/** Header **/

header {
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
}

header .top-bar {
  color: #495057;
  background: #f2f7fb;
  padding: 1rem;
  font-weight: 400;
}

header .site-logo {
  /* width: 80%; */
  width: auto;
  max-width: 185px;
  max-height: 43px;
}

.site-banner {
  max-height: 351 px !important;
}

#home-page section,
#subscription-plan-page section {
  padding: 0;
  min-height: auto;
}

/* 20,March 19 */

.pd-t-20 {
  padding-top: 20px;
}

.m-t-20 {
  margin-top: 20px;
}

.banner {
  position: relative;
  border: 1px solid red;
  height: 650px;
}

.banner-text {
  box-sizing: border-box;
  color: #fff;
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 1300px) {
  .banner {
    height: 650px;
    width: 100%;
    background-color: #000;
    text-align: center;
  }
  .banner-text {
    min-height: 31rem;
    margin-top: 7rem;
  }
}

@media only screen and (min-width: 1600px) {
  .banner-text {
    min-height: 37.6rem;
    box-sizing: border-box;
    color: #fff;
  }
}

.banner img {
  /* max-height: 650px; */
  height: 100% !important;
  opacity: 0.7;
}
/** **/

.chevron::before {
  border-style: solid;
  border-color: #e2e2e2;
  border-width: 0.35em 0.35em 0 0;
  content: '';
  display: inline-block;
  height: 1em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 1em;
}

.chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}

.chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}

/** Subscription Banner **/

#home-page .subscription-banner {
  opacity: 0.8;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

/** Blogs **/

#home-page .blogs .blog-calender {
  border: 2px solid #fc987f;
  padding: 5px 10px;
  color: #fc987f;
  font-weight: 600;
}

/** Gallert **/

#home-page .gallery-item img {
  width: 540px;
  height: 380px;
}

/** Testimonial **/

#home-page .testimonial .testimonial-item {
  font-size: 20px;
  font-weight: 100;
  opacity: 0.8;
  padding: 1rem 2rem;
}

/** Newsletter **/

#home-page .newsletter {
  background-repeat: repeat;
  background-position: center;
  background-size: contain;
  background-attachment: fixed;
}

#home-page .newsletter .input-group {
  width: 50%;
}

#home-page .newsletter .newsletter-input-group input.form-control {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

/** subscription plan **/

#subscription-plan-page .subscription-plan {
  transition: all 300ms ease-out;
}

#subscription-plan-page .subscription-plan:hover {
  transform: scale(1.05);
}

#subscription-plan-page .card .card-pricing {
  min-height: 200px;
}
#subscription-plan-page .card .card-pricing .price {
  font-weight: 400;
  font-size: 5.6rem !important;
}

.sp-blue {
  color: #fafafa;
  border: none !important;
  background: linear-gradient(to bottom, #45a1de, #fff);
  /* background: linear-gradient(to bottom, #ffb41f, #ffce6b); */
}

.sp-yellow {
  color: #fafafa;
  border: none !important;
  background: linear-gradient(to bottom, #ffb41f, #fff);
  /* background: linear-gradient(to bottom, #ffb41f, #ffce6b); */
}

.sp-green {
  color: #fafafa;
  border: none !important;
  background: linear-gradient(to bottom, #3bd37c, #fff);
  /* background: linear-gradient(to right, #01a9ac, #01dbdf); */
}

.sp-red {
  color: #fafafa;
  border: none !important;
  background: linear-gradient(to bottom, #e95d4e, #fff);
  /* background: linear-gradient(to right, #fe5d70, #fe909d); */
}

/* .owl-carousel .owl-nav{
    position: absolute;
    top:50%;
    width:100%;
    z-index: 1;
    border:solid 1px red;
    margin-top: -30px;
} */

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  font-size: 3rem;
  font-weight: bold;
  width: 30px;
  top: 50%;
  margin-top: -60px;
  margin-left: 0px;
  margin-right: 0px;
  position: absolute;
  opacity: 0.4;
}
.owl-prev {
  left: -15px;
}
.owl-next {
  right: -15px;
}
.bottom-content .accordion-group .accordion-item {
  padding: 10px 15px !important;
  height: auto;
}
.bottom-content .accordion-group .accordion-item span {
  margin-top: 7px;
  display: inline-block;
}

#register-success-page .card .card-pricing .price {
  font-weight: 400;
  font-size: 6.25rem !important;
}

.blogs .card .card-body .media-body .text-small p {
  /* overflow: hidden;
  text-overflow: ellipsis;
  height: 86px; */
}

/* styles for '...' */
.blogs .card .card-body .media-body .text-small p {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 3.6em;
  /* fix problem when last visible word doesn't adjoin right side  */
  /* text-align: justify; */
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}
/* create the ... */

@media screen and (max-width: 991px) {
  #home-page .newsletter .input-group {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .subscription-banner .btn {
    margin: 0px auto;
  }

  .hide-on-mobile {
    display: none !important;
  }

  .bottom-content .accordion-group .accordion-item .ml-4 {
    margin-left: 0.9rem !important;
    margin-top: 6px;
  }
  .bottom-content .accordion-group .accordion-item span {
    max-width: 60%;
  }
  #home-page .gallery-item img {
    height: auto;
  }
}
