#login-page {
    padding: 10px;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

#login-page .login-form {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 2px;
    margin: 10px auto 30px;
    max-width: 40%;
    padding: 20px;
}

#login-page .login-form #login {
    width: 100%;
}

#login-page .login-form .form-check{
    font-size: 0.9rem;
    display: inline-block;
}

#login-page .form-option {
    color: #9ca6af;
    font-size: 1rem;
    margin: 10px auto;
    position: relative;
}

#login-page .form-option:before, #login-page .form-option:after {
    content: "";
    position: absolute;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    left: 10%;
    height: 0;
    top: 50%;
    width: 30%;

}

#login-page .form-option:before {
    left: auto;
    right: 10%;
}

#login-page .login-form .btn.btn-social {
    box-sizing: border-box;
    position: relative;
    margin: 0.2rem;
    border: none;
    font-size: 1rem !important;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 2px;
}

#login-page .login-form .btn.btn-social:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
}

#login-page .login-form .btn.btn-facebook {
    background-color: #4C69BA;
    background-image: linear-gradient(#4C69BA, #3B55A0);
    text-shadow: 0 -1px 0 #3B55A0;
}

#login-page .login-form .btn.btn-facebook:before {
    border-right: 1px solid #3B55A0;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}

#login-page .login-form .btn.btn-google {
    background-color: #DD4B39;
    background-image: linear-gradient(#DD4B39, #BB3F30);
    text-shadow: 0 -1px 0 #BB3F30;
}

#login-page .login-form .btn.btn-google:before {
    border-right: #BB3F30 1px solid;
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png) 6px 6px no-repeat;
}

#login-page .login-form .card-footer {
    color: #777777;
    background-color: #fff;
    padding: 1rem 1.25rem;
}

#login-page .forgot-link{
    line-height: 2rem;
}

@media only screen and (max-width: 1199px) {
    #login-page .login-form {
        max-width: 50%;
        padding: 15px;
    }
}

@media only screen and (max-width: 991px) {
    #login-page .login-form {
        max-width: 70%;
        padding: 15px;
    }
}

@media only screen and (max-width: 767px) {
    #login-page .login-form {
        max-width: 100%;
        padding: 10px 0;
    }
}